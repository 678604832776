.user {
    display: block;
    width: 100%;
    padding: var(--mantine-spacing-md);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-8));
    }
  }
.userName {
  max-width: 98px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profileButton {
  min-width: 140px;
}

.accSubLink {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
  justify-content: center;
  display: flex;

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
  }
}


.accLink {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  width: 100%;
  justify-content: center;

  @media (max-width: $mantine-breakpoint-sm) {
    height: rem(42px);
    width: 100%;
  }

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-6)
    );
  }
}