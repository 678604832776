.consent {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex: unset !important;
}

.consent > p {
    align-self: center;
    text-align: left;
    width: 100%;
}
.consentGrid {
    justify-content: center !important;
    max-width: 18em;
    left: unset !important;
}
.consentGrid > div:first-child {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr fr;
    grid-template-columns: 1fr;
    max-width: 99em;
}

/* Media Query für kleinere Bildschirme */
@media (max-width: 768px) {
    .consentGrid {
        right: 0px !important;
        max-width: 100%;
    }
    .consentGrid > div {
        grid-template-columns: 1fr;
    }
}
@media (min-width: 768px) {
    .consentGrid {
        right: 15px !important;
        box-shadow: 1px 0 2em #111;
    }
    .buttonGrid {
        max-width: 15em;
        justify-self: center;
    }
}

.buttonGrid {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr fr;
}
.buttonGrid Button {
    margin: 5px 0px;
    width: 100%
}

.consentModal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
}