.cartModal section {
    
    background-color: transparent;
}
.cartModal > div:first-child {
    background: var(--overlay-bg, rgba(0, 0, 0, 0.6));
}
@media (max-width: 50em) {
    .cartModal > div:first-child {
        background: var(--overlay-bg, rgba(0, 0, 0, 0.9));
    }
}

.cartModal header {
    background-color: transparent;
}
.cartModal header > h2 {
    margin: auto;
}
.cartModal header > button {
    position: absolute;
    right: 10px;
    top: 27px;
}