.steambutton {
  display: block;
  background-color: #d75f0f;
  width: 150px;
  height: 40px;
  line-height: 41px;
  margin: auto;
  color: #fff;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
}

.steambutton span {
  font-family: "Exo 2", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  width: 75%;
  font-size: 14px;
  text-transform: uppercase;
  left: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}
.steambutton .icon .fa {
  font-size: 30px;
  line-height: 50px;
  -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4),
    height 0.25s ease;
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
}

.steambutton .icon {
  width: 25%;
  right: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}

.steambutton span,
.steambutton .icon {
  display: block;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.steambutton > span:after {
  content: "";
  background-color: #ffffff;
  width: 2px;
  height: 70%;
  position: absolute;
  top: 15%;
  right: 10px;
}
.steambutton span > svg {
  height: 100%;
}


.steambutton.success span > svg,
.steambutton:hover span > svg {
  width: 40px;
}

.steambutton.success span,
.steambutton:hover > span {
  left: -72%;
  opacity: 1;
}

.steambutton.success .icon,
.steambutton:hover .icon {
  width: 100%;
}

.steambutton.success .icon .fa,
.steambutton:hover .icon .fa {
  font-size: 45px;
}
