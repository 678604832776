.content {
  width: 80%;
  font-family: "RobotoBlack";
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  margin-right: calc(var(--mantine-spacing-xl) * 3);
}

.title {
  color: var(--mantine-color-white);
  font-family: "RobotoBlack";
  font-weight: 900;
  line-height: 1.05;
  max-width: rem(500px);
  font-size: rem(48px);
}

.description {
  color: var(--mantine-color-white);
  opacity: 0.75;
  max-width: rem(500px);
}

.control {
  padding-left: rem(50px);
  padding-right: rem(50px);
  font-family: "RobotoRegular";
  font-size: rem(22px);
}
