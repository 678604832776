.title {
  font-size: rem(62px);
  font-weight: 900;
  line-height: 1.1;
  margin: 0;
  padding: 0;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: rem(42px);
    line-height: 1.2;
  }
}

.alignedCenter {
  padding-top: 4%;
}
