.featureCardGroup {
  margin: auto;
  width: 75%;
  padding-bottom: 4%;
}

.refunded {
  background-color: #f83a2c;
}

.badge > div {
  z-index: unset;
  color: var(--mantine-color-dark-0);
  font-weight: normal;
  margin-right: -75px;
}