.styledInput {
    height: 55px;
}
.styledInput * {
    height: 100%;
}

.styledInput input {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    font-family: "RobotoMedium";
}

.styledPaypal {
    color-scheme: none;
    padding: 15px;
    background-color: #fff;
    border-radius: 15px;
}

.amountSelection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.cartSumSelection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}
  
  .amountLabel {
    margin-right: 1rem;
    font-weight: bold;
    color: black;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px; /* Adjust this to match your NumberInput height */
  }
  .cartSum {
    font-weight: bold;
    color: black;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px; /* Adjust this to match your NumberInput height */
    justify-self: end;
  }
  .cartSumDiscountGroup {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px; /* Adjust this to match your NumberInput height */
    justify-self: end;
  }
  .cartSumDiscounted {
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px; /* Adjust this to match your NumberInput height */
    justify-self: end;
  }

  .strikethrough {
    position: relative;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: var(--mantine-color-red-8);
    
    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg);
    transform:rotate(-5deg);
  }
  
  .styledInput {
    flex-grow: 1;
    width: 100%;
  }
