@font-face {
  font-family: "RobotoBlack";
  src: local("RobotoBlack"),
    url(fonts/Roboto-Black.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url(fonts/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"),
    url(fonts/Roboto-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
    url(fonts/Roboto-Regular.ttf) format("truetype");
}

body {
  font-family: "RobotoRegular";
}

strong {
  font-family: "RobotoMedium";
}

.header {
  position: fixed;
  background-color: "#1a1b1e";
  padding-top: var(--mantine-spacing-xs);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  z-index: 1000;
  border-bottom: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  font-size: var(--mantine-font-size-xl);

  @media (max-width: $mantine-breakpoint-sm) {
    height: rem(42px);
    width: 100%;
  }

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-6)
    );
  }
}

.button {
  cursor: pointer;
}

.subLink {
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
  }
}

.dropdownFooter {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-7)
  );
  margin: calc(var(--mantine-spacing-md) * -1);
  margin-top: var(--mantine-spacing-sm);
  padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
  padding-bottom: var(--mantine-spacing-xl);
  border-top: rem(1px) solid
    light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}
