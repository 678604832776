.videoContainer {
  position: relative;
  width: "100%";
  height: 700px;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  z-index: 100;
}
