.control {
    padding-left: rem(50px);
    padding-right: rem(50px);
    font-family: "RobotoRegular";
    font-size: rem(22px);
  }

.flexContainer {
    margin-top: 1em;
    margin-bottom: 1em;
}